import { HTMLProps } from 'react';
import styled from 'styled-components';
import { ButtonBase } from './components/ButtonBase';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// All ButtonBase takes care of all the default stuff
export const PrimaryButton = styled(ButtonBase)<HTMLProps<HTMLButtonElement>>`
  background: ${(p) => p.theme.getRoleColor('secondary', 'primary')};
  font-size: ${(p) => p.theme.fontSizes[3]};

  &:hover:enabled,
  &:focus:enabled {
    background: ${(p) => p.theme.getRoleColor('B700')};
  }
`;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SecondaryButton = styled(ButtonBase)`
  background: ${(p) => p.theme.colors.transparent};
  color: ${(p) => p.theme.getRoleColor('N900', 'N900')};
  border: 1px solid ${(p) => p.theme.getRoleColor('N900', 'N900')};
  font-size: ${(p) => p.theme.fontSizes[3]};

  &:hover:enabled,
  &:focus:enabled {
    background: ${(p) => p.theme.getRoleColor('N900', 'N900')};
    color: ${(p) => p.theme.colors.white};
  }
`;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const UnderlineButton = styled(ButtonBase)`
  padding: 0;
  background: transparent;
  font-size: inherit;
  font-weight: 600;
  font-size: 12px;
  color: ${(p) => p.theme.getRoleColor('N600', 'B700')};
  text-decoration: underline;

  &:hover:enabled,
  &:focus:enabled {
    color: ${(p) => p.theme.getRoleColor('N700')};
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const TransparentButton = styled(ButtonBase)`
  background: transparent;
  padding: 0;
  color: inherit;
`;

export default PrimaryButton;
