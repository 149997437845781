import styled from 'styled-components';
import { Flex } from '~components';

export const Nav = styled(Flex).attrs({ as: 'nav' })`
  height: 56px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  z-index: 14;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: ${(p) => p.theme.colors.white};
  border-bottom: 1px solid ${(p) => p.theme.getRoleColor('N300', 'N300')};

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    height: 100vh;
    width: 64px;
    position: relative;
    flex-direction: column;
    padding: 24px 0;
    background-color: ${(p) => p.theme.getRoleColor('primary', 'primary')};
    border: none;
  }

  z-index: 99;
`;
