import { showToast, getError } from '~lib/helpers';

// pretty useless typescript assignment but okay
interface PromiseData {
  data: any;
}

export default (
  promise: Promise<PromiseData>,
  toast?: { success?: string; errorMsg?: boolean | string },
) =>
  promise
    .then((result: PromiseData) => {
      if (toast?.success) {
        showToast(toast?.success, 'success');
      }
      return [null, result?.data];
    })
    .catch((error) => {
      if (toast?.errorMsg || toast?.errorMsg === undefined) {
        const msg =
          typeof toast?.errorMsg === 'string'
            ? toast?.errorMsg
            : getError(error);

        showToast(msg, 'error');
      }
      return [error, null];
    });
