import styled from 'styled-components';

import { Flex } from '../Flex';

const Content = styled(Flex)`
  background: ${(p) => p.theme.colors.white};

  min-width: 100%;

  padding: 24px;

  border-radius: 4px;

  box-shadow: 0px 0.940867px 2.21381px rgba(0, 0, 0, 0.01),
    0px 2.26103px 5.32008px rgba(0, 0, 0, 0.01),
    0px 4.25733px 10.0172px rgba(0, 0, 0, 0.01),
    0px 7.59435px 17.869px rgba(0, 0, 0, 0.02),
    0px 14.2044px 33.4221px rgba(0, 0, 0, 0.03),
    0px 34px 80px rgba(0, 0, 0, 0.04);
`;

export default Content;
