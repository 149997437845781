export default (err: any) => {
  let errMsg = 'An error has occured';

  if (err) {
    if (err.response) {
      if (err.response.data?.message) {
        errMsg = Array.isArray(err.response.data.message)
          ? err.response.data?.message[0]
          : err.response.data?.message;
      } else if (err.response.data?.error) {
        errMsg = err.response.data?.error;
      } else {
        // could come up with some useful status codes
        errMsg = err.status;
      }
    }
  }

  return errMsg;
};
