import { forwardRef, HTMLProps, ReactNode } from 'react';

import { pick, omit } from '@styled-system/props';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

import { Layout, ILayout } from '~components';
import { ISystem } from '~lib';

interface WrapperProps {
  isValid?: boolean;
  error?: string;
  pl?: string;
  as?: string;
}

export const InputWrapper = styled.div<WrapperProps>`
  position: relative;
  font-size: ${(p) => p.theme.fontSizes[2]};

  &:after {
    position: absolute;
    top: 17px;
    right: ${(p) => p.theme.space[4]}px;
    opacity: ${(p) => (p.isValid ? 1 : 0)};
    content: '';
    width: 10px;
    height: 6px;
    border-left: 2px solid ${(p) => p.theme.getRoleColor('N900', 'N900')};
    border-bottom: 2px solid ${(p) => p.theme.getRoleColor('N900', 'N900')};
    transition: opacity 0.1s ease;
    transform: rotate(-40deg);
  }

  &:hover input,
  &:focus input {
    border-color: ${(p) => p.theme.getRoleColor('N800')};
  }
`;

const TextLeft = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: ${(p) => p.theme.space[4]}px;
  height: 100%;
  z-index: 1;
  color: ${(p) => p.theme.getRoleColor('N600', 'N700')};
`;

export const StyledInput = styled.input<WrapperProps>`
  position: relative;
  padding: ${(p) => p.theme.space[4]}px;
  ${(p) => p.pl && `padding-left: ${p.pl}`};
  ${(p) => p.isValid && `padding-right: 35px`};
  height: ${(p) => (p.as === 'textarea' ? 'auto' : '40px')};
  width: 100%;
  border: 1px solid
    ${(p) =>
      p.isValid
        ? p.theme.getRoleColor('N800', 'N800')
        : p.error
        ? p.theme.colors.R400
        : p.theme.colors.professional.N400};
  border-radius: 4px;
  background-color: ${(p) => p.theme.getRoleColor('N200')};
  color: ${(p) => p.theme.getRoleColor('N900', 'N900')};
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${(p) => p.theme.getRoleColor('N800')};
  }
`;

interface Props extends ISystem {
  isValid?: boolean;
  textLeft?: { text: string; padding: string };
  children?: ReactNode;
  mask?: any;
  maskOptions?: any;
}

const TextInput = forwardRef(
  (
    {
      isValid,
      error,
      label,
      textLeft,
      mask,
      maskOptions,
      ...props
    }: Props & HTMLProps<HTMLInputElement> & ILayout,
    ref,
  ) => {
    const maskedInputProps = mask
      ? { mask, as: MaskedInput, keepCharPositions: true, ...maskOptions }
      : {};

    return (
      <Layout {...pick(props)} error={error} name={props.name} label={label}>
        <InputWrapper isValid={isValid && !!props.value}>
          {textLeft && <TextLeft>{textLeft?.text}</TextLeft>}
          <StyledInput
            {...omit(props)}
            as={props.as}
            isValid={isValid && !!props.value}
            error={error}
            pl={textLeft?.padding}
            {...maskedInputProps}
            ref={ref}
          />
        </InputWrapper>
      </Layout>
    );
  },
);

export default TextInput;
