import { FC, ReactNode, useState } from 'react';
import { animated, useSpring, useTransition } from 'react-spring';
import styled from 'styled-components';
import uniqid from 'uniqid';

import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { Text } from '../Text';

export const Title = styled(Text).attrs({
  as: 'h3',
  mb: { default: 6, md: 4 },
  pb: 2,
  fontSize: 5,
  fontWeight: 'bold',
  borderBottom: '2px',
  borderColor: 'N900',
})``;

const StyledIcon = styled(Icon).attrs({
  icon: 'chevron-up',
  fontSize: 6,
  color: 'professional.B700',
})<{ isCollapsed: boolean }>`
  transform: rotate(${(p) => (p.isCollapsed ? '180' : '0')}deg);
  transition: transform 0.25s cubic-bezier(0.09, 1.08, 0.77, 1.18);
`;

const Wrapper = styled(Flex).attrs({
  flexDirection: 'column',
  width: 1,
})``;

const Content = styled(Flex).attrs({
  flexDirection: 'column',
  width: 1,
})``;

interface Props {
  id?: string;
  title: string;
  children: ReactNode;
}

const Collapsible: FC<Props> = ({ id: givenId, title, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const id = givenId || uniqid();

  const AnimatedSection = animated(Wrapper);
  const AnimatedContent = animated(Content);

  const springProps = useSpring({
    rotate: isCollapsed ? 180 : 0,
    height: isCollapsed ? 54 : 100,
  });
  const transition = useTransition(!isCollapsed, {
    from: { transform: -20, opacity: 0 },
    enter: { transform: 0, opacity: 1 },
    leave: { transform: -20, opacity: 0 },
  });

  const handleCollapse = (e) => {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
  };

  return (
    <AnimatedSection
      id={id}
      style={{
        height: springProps.height.interpolate(
          (value) => `${value}${isCollapsed ? 'px' : '%'}`,
        ),
      }}
      mb="16px"
      key={id}
      minH="fit-content"
    >
      <Flex
        justifyContent="space-between"
        position="relative"
        bg="white"
        zIndex={10}
        onClick={handleCollapse}
        as="button"
        type="button"
      >
        <Title flex={1} color="professional.B700">
          {title}
        </Title>
        <Flex
          position="absolute"
          right={0}
          top="calc(50% - 12px -16px)"
          p={1}
          color="professional.B700"
        >
          <StyledIcon icon="chevron-up" isCollapsed={isCollapsed} />
        </Flex>
      </Flex>

      {transition(
        (styles, item) =>
          item && (
            <AnimatedContent
              style={{
                height: springProps.height.interpolate(
                  (value) => `${value}${isCollapsed ? 'px' : '%'}`,
                ),
                opacity: styles.opacity,
              }}
            >
              {children}
            </AnimatedContent>
          ),
      )}
    </AnimatedSection>
  );
};

export default Collapsible;
