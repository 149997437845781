import { useContext } from 'react';
import * as React from 'react';
import ReactSelect, { Props } from 'react-select';
import styled, { css, ThemeContext } from 'styled-components';
import { system, ISystem } from '~lib';

interface InputProps {
  isValid?: boolean;
}

const StyledSelect = styled(ReactSelect).attrs({
  className: 'react-select',
  classNamePrefix: 'react-select',
})<ISystem & InputProps>(
  ({ styledTheme: theme, isValid }) => css`
    ${system}

    font-size: 14px;
    width: 100%;

    .react-select {
      &__placeholder {
        color: ${theme.getRoleColor('N600')};
      }

      &__menu {
        z-index: 20 !important;
      }

      &__control {
        height: 40px;
        width: 100%;
        transition: border-color 0.18s ease;
        border-radius: ${theme.radii.sm};
        box-shadow: none;
        background-color: ${theme.getRoleColor('N200')};
        ${!isValid &&
        `
          box-shadow: 0 0 1px ${`${theme.colors.R400}`};
        `}

        &:hover,
        &:focus,
        &--is-focused {
          border-color: ${theme.getRoleColor('N800')};

          .react-select__dropdown-indicator {
            svg {
              fill: ${theme.getRoleColor('N800')};
            }
          }
        }

        .react-select__dropdown-indicator {
          svg {
            fill: ${theme.getRoleColor('N600')};
            transition: all 0.25s cubic-bezier(0.09, 1.08, 0.77, 1.18);
            transform: rotate(0deg);
          }
        }

        &--menu-is-open {
          .react-select__dropdown-indicator {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &--is-disabled {
          background: ${theme.colors.grey200};
          .react-select__placeholder {
            color: ${theme.colors.grey700};
          }
        }
      }

      &__value-container {
        padding-left: 14px;
      }

      &__indicator-separator {
        display: none;
      }
    }
  `,
);

export interface ISelect extends Props {
  theme?: any;
}

export const Select: React.FC<ISelect> = (props) => {
  const styledTheme = useContext(ThemeContext); // react-select and styled-components both need a theme so it needs to be renamed

  return (
    <StyledSelect
      styledTheme={styledTheme}
      theme={(t) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: styledTheme.getRoleColor('N200'),
          primary50: styledTheme.getRoleColor('N400'),
          primary: styledTheme.getRoleColor('N800'),
        },
      })}
      {...props}
    />
  );
};

export default Select;
