import { FC, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Text, Pagination } from '~components';
import { useKeyboard } from '~hooks';

interface ITableProps {
  header?: ReactNode;
  row: ReactNode;
  data: any[];
  _data?: any[];
  withHeaders?: boolean;
  pagination?: any[];
  rowProps?: any;
  placeholder?: any;
}

const StyledTable = styled(Flex)`
  flex: 1;
  flex-direction: column;
`;

const TableBody = styled(Flex)`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  div:last-of-type {
    margin-bottom: 0;
  }
`;
const TableFooter = styled(Flex)`
  justify-content: flex-end;
`;

const NoResultRow = ({ customRow }) => (
  <Flex justifyContent="center" alignItems="center" flex={1}>
    {customRow || (
      <Text color="professional.N900" fontSize={2}>
        Geen resultaten
      </Text>
    )}
  </Flex>
);

const renderRows = (
  Row,
  data,
  _data = [],
  activeRow = 0,
  rowProps,
  placeholder,
) => {
  let rows = _data?.map((d, key) => (
    <Row rowKey={key} activeRow={activeRow} {...d} {...rowProps} />
  ));

  if (Array.isArray(data)) {
    if (data.length > 0) {
      rows = data?.map((d, key) => (
        <Row
          rowKey={key}
          key={key}
          activeRow={activeRow}
          {...d}
          {...rowProps}
        />
      ));
    } else {
      rows = [<NoResultRow customRow={placeholder} />];
    }
  }

  return rows;
};

const renderPagination = (pagination) => (
  <TableFooter mt="24px">
    <Pagination pagination={pagination} />
  </TableFooter>
);

const scrollToRow = (id: string) =>
  document.getElementById(id).scrollIntoView();
const rowClick = (id: string) => document.getElementById(id).click();

const Table: FC<ITableProps> = ({
  row,
  rowProps,
  data,
  pagination,
  _data = [],
  header = null,
  placeholder,
}) => {
  let footers = null;
  const [activeRow, setActiveRow] = useState(0);

  useEffect(() => {
    if (!!data?.length && data?.length !== 10) {
      // reset state if not all rows are present to prevent errors
      setActiveRow(0);
    }
  }, [data?.length]);

  const keyboard = useKeyboard([
    'Enter',
    'Tab',
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'ArrowLeft',
  ]);

  useEffect(() => {
    if (keyboard.active) {
      if (keyboard.currentKey === 'Enter' && data?.length > 0) {
        rowClick(`${activeRow}`);
      }

      if (
        keyboard.currentKey === 'ArrowDown' &&
        activeRow < rows.length - 1 &&
        data?.length > 0
      ) {
        setActiveRow(activeRow + 1);
        scrollToRow(`${activeRow + 1}`);
      } else if (
        keyboard.currentKey === 'ArrowUp' &&
        activeRow > 0 &&
        data?.length > 0
      ) {
        setActiveRow(activeRow - 1);
        scrollToRow(`${activeRow - 1}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboard]);

  if (pagination) {
    footers = renderPagination(pagination);
  }

  const rows = renderRows(row, data, _data, activeRow, rowProps, placeholder);

  return (
    <StyledTable>
      {header}
      <TableBody>{rows}</TableBody>
      {footers}
    </StyledTable>
  );
};

export default Table;
