import { useState } from 'react';
import copy from 'copy-to-clipboard';

import { toast } from 'react-toastify';
import { ToastAlert } from '~components';

const useCopyToClipboard = (): [boolean, (text: any) => void] => {
  const [isCopied, setCopied] = useState(false);

  const handleCopy = (text) => {
    if (typeof text === 'string' || typeof text == 'number') {
      copy(text.toString());
      setCopied(true);
      toast(({ closeToast }) => (
        <ToastAlert
          msg={'Gekopieerd naar klembord!'}
          type={'success'}
          closeToast={closeToast}
        />
      ));
    } else {
      setCopied(false);
      toast(({ closeToast }) => (
        <ToastAlert
          msg={'Kan deze tekst niet kopieren naar klembord.'}
          type={'error'}
          closeToast={closeToast}
        />
      ));
      console.error(
        `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`,
      );
    }
  };

  return [isCopied, handleCopy];
};

export default useCopyToClipboard;
