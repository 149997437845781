const b64ToBlob = (
  b64String: string,
  type:
    | 'application/octet-stream'
    | 'application/pdf'
    | 'image/png' = 'application/pdf',
) => fetch(`data:${type};base64,${b64String}`).then((res) => res.blob());

export const tempFile = async (
  base64: string,
  type?: 'application/octet-stream' | 'application/pdf' | 'image/png',
) => {
  const blob = await b64ToBlob(base64, type).catch((e) => console.error(e));
  if (typeof window !== 'undefined') {
    window.open(URL.createObjectURL(blob));
  }
};
