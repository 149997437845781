import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export const formatDateFromString = (date) => {
  const dateArray = date.split('-');
  const formattedDate = [dateArray[1], dateArray[0], dateArray[2]].join('-');

  return formattedDate;
};

export const formatDate = (
  date: Date | string,
  dateFormat: string = 'dd/MM/yyyy',
) => date && format(new Date(date), dateFormat, { locale: nl });
