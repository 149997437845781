import { Flex, Button, HelpModal, Text, TransparentButton } from '~components';

const ConfirmationModal = ({
  isOpen,
  close,
  onConfirm,
  title,
  text,
  buttonText,
}) => (
  <HelpModal showHelp={isOpen} close={close}>
    <Flex flexDir="column" justifyContent="center" p={5}>
      <Text fontWeight="bold" fontSize={3} color="professional.N900" mb={5}>
        {title}
      </Text>
      <Text fontSize={3} mb={6}>
        {text}
      </Text>
    </Flex>
    <Flex justifyContent="flex-end" mt="auto">
      <TransparentButton onClick={close} mr={3} px={5} fontSize={3}>
        Cancel
      </TransparentButton>
      <Button onClick={onConfirm} bg="R400">
        {buttonText}
      </Button>
    </Flex>
  </HelpModal>
);

export default ConfirmationModal;
