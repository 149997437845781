import axios from 'axios';

import { apiUrl } from '~config';

import { tempFile } from './downloadBlob';
import handlePromise from './handlePromise';

export const downloadConsultation = async (caseId: string) => {
  const [error, data] = await handlePromise(
    axios.get(`${apiUrl}/case/${caseId}/pdf`),
  );

  if (error) {
    return;
  }

  return tempFile(data);
};
