import { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import useSWR from 'swr';

interface ITableHook {
  swrKey: string;
  initialOffset?: number | undefined;
  initialLimit?: number | undefined;
  initialSort?: { key: string; direction: string } | undefined;
}

interface ITableHookResponse {
  data: any;
  error: any;
  mutate: any;
  limit: number;
  offset: number;
  sort: { key: string; direction: string };
  filters: any;
  setLimit(limit: number): void;
  setOffset(offset: number): void;
  setSort(sort: { key: string; direction: string }): void;
  handleFilters(key: string, value: string | string[]): void;
}

type IUseTable = ({
  swrKey,
  initialOffset,
  initialLimit,
  initialSort,
}: ITableHook) => ITableHookResponse;

const useTable: IUseTable = ({
  swrKey,
  initialOffset = 0,
  initialLimit = 10,
  initialSort = { key: 'default', direction: 'asc' },
}) => {
  const [limit, setLimit] = useState<number>(initialLimit);
  const [offset, setOffset] = useState<number>(initialOffset);
  const [sort, setSort] = useState<{ key: string; direction: string }>(
    initialSort,
  );
  const [filters, setFilters] = useState({});

  const handleFilters = (key: string, val: string) =>
    setFilters({ ...filters, [key]: val });

  const { error, data, mutate } = useSWR(
    swrKey ? [swrKey, limit, offset, sort, filters] : null,
    (url, limit, offset, sort, filters) =>
      axios
        .get(
          `${url}?${qs.stringify({
            limit,
            skip: offset * limit,
            sort,
            filter: filters,
          })}`,
        )
        .then((res) => res.data),
  );

  return {
    data,
    error,
    mutate,
    limit,
    offset,
    sort,
    setLimit,
    setOffset,
    setSort,
    filters,
    handleFilters,
  };
};

export default useTable;
