import { useState } from 'react';
import includes from 'lodash/includes';

const useSelection = (): [
  string[],
  (item: string | number) => void,
  (item: string | number) => boolean,
  () => void,
  (item: any[]) => void,
] => {
  const [selection, setSelection] = useState([]);

  const toggleSelected = (selected) => {
    if (!selected) return;

    if (includes(selection, selected)) {
      return setSelection(selection.filter((item) => item !== selected));
    }

    return setSelection([...selection].concat(selected));
  };

  const clearSelection = () => setSelection([]);

  const isSelected = (item) => !!includes(selection, item);

  return [selection, toggleSelected, isSelected, clearSelection, setSelection];
};

export default useSelection;
