import { createContext, useState, useRef, ReactNode } from 'react';
import { get } from 'lodash';
import { IAccount } from '~lib/abstractions';

interface IContext {
  user: IAccount;
  role: string;
  isAuth: boolean;
  setIsAuth(value: boolean): void;
  isLoading: boolean;
  isChecking: any;
  setIsLoading(value: boolean): void;
  setUser(value: IAccount): void;
}

interface Props {
  children: ReactNode;
}

const getRole = (user) => {
  const professionalRoles = ['gp', 'opto', 'ophthal'];
  const role = get(user, 'role', 'professional');

  return professionalRoles.includes(role) ? 'professional' : role;
};

const UserContext = createContext<IContext | undefined>(undefined);

const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState(undefined);
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isChecking = useRef(false);
  const role = getRole(user);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        role,
        isAuth,
        setIsAuth,
        isLoading,
        setIsLoading,
        isChecking,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
