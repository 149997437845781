import { FC } from 'react';

import { get } from 'lodash';
import styled from 'styled-components';

import { Box, Button, Icon } from '~components';
import { downloadConsultation } from '~lib/helpers';
import { ISystem } from '~lib/system';

export const ShowOnPrint = styled(Box)`
  @media print {
    display: block !important;
  }
`;

export const HideOnPrint = styled(Box)`
  @media print {
    display: none !important;
  }
`;

const StyledButton = styled(Button)`
  padding: 6px 8px;
  line-height: 1;
  font-size: 14px;

  @media print {
    display: none;
  }
`;

interface Props {
  consult: any;
  config: any;
}

export const PrintButton: FC<ISystem & Props> = ({
  consult,
  config,
  ...props
}) => {
  const printableItems = config?.consultation.filter(
    (item: { key: string }) =>
      item.key !== 'fundusPhoto' &&
      item.key !== 'oct' &&
      item.key !== 'octScan' &&
      (get(consult, item.key) ||
        Object.keys(get(consult, item.key, [])).length),
  );

  const print = () => downloadConsultation(consult?.case?.id);

  return (
    <StyledButton
      onClick={print}
      className="hidden-mobile"
      {...props}
      disabled={!printableItems?.length}
    >
      <Icon icon="print" fontSize="16px" color="white" mr={1} />
      Print
    </StyledButton>
  );
};
