import { ReactNode } from 'react';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { UserProvider, UserContext, ConfigProvider } from '~context';
import { getColor } from '~lib/helpers';
import theme from '~styles/theme';

interface Props {
  children: ReactNode;
}

const Providers: React.FC = ({ children }: Props) => {
  return (
    <UserProvider>
      <UserContext.Consumer>
        {({ role }) => (
          <ConfigProvider>
            <ThemeProvider
              theme={{
                ...theme,
                getRoleColor: (professional, patient) =>
                  getColor(theme.colors, role, professional, patient),
              }}
            >
              {children}
            </ThemeProvider>
          </ConfigProvider>
        )}
      </UserContext.Consumer>
    </UserProvider>
  );
};

export default Providers;
