import styled from 'styled-components';
import { Button, Icon, Text } from '~components';

const PaginationItem = styled(Button)<{ active?: boolean }>`
  background: none;
  padding: 0 6px;

  color: ${(p) =>
    !p.active || p.disabled
      ? p.theme.colors.professional.N500
      : p.theme.colors.professional.N900};

  &:hover:enabled {
    background: none;
    color: ${(p) => p.theme.colors.professional.N900};
  }

  svg {
    fill: ${(p) =>
      !p.active || p.disabled
        ? p.theme.colors.professional.N500
        : p.theme.colors.professional.N900};
  }
`;

const Pagination = ({ pagination }) =>
  pagination.map((page) => {
    if (page.type === 'icon') {
      return (
        <PaginationItem
          onClick={page.onClick}
          disabled={page.disabled}
          key={page.icon}
        >
          <Icon icon={page.icon} />
        </PaginationItem>
      );
    }

    return (
      <PaginationItem
        active={page.active}
        onClick={page.onClick}
        style={{ cursor: 'pointer' }}
        key={page.label}
      >
        <Text as="span" fontSize={1} fontWeight="bold">
          {page.label}
        </Text>
      </PaginationItem>
    );
  });

export default Pagination;
