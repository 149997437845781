import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { Flex, Icon } from '~components';
import { useKeyboard } from '~hooks';
// import {isEmpty} from 'lodash'

interface ISearchbar {
  handleSearch(value: string): void;
  placeholder?: string;
  maxWidth?: string;
}

const StyledInput = styled.input`
  border: none;

  width: 450px;
  margin-right: 12px;
  text-align: right;

  font-size: 14px;
  font-weight: bold;
  color: ${(p) => p.theme.colors.professional.N900};
`;

const Searchbar: FC<ISearchbar> = ({ handleSearch, ...props }) => {
  const keyboard = useKeyboard(['/', 'Escape'], { preventDefault: true });
  const searchRef = useRef(null);
  const debouncedCb = useDebouncedCallback((value: string) => {
    handleSearch(value);
  }, 300);

  useEffect(() => {
    if (keyboard.active) {
      if (keyboard.currentKey === '/') {
        searchRef.current.focus();
      }

      if (keyboard.currentKey === 'Escape') {
        searchRef.current.blur();
      }
    }
  }, [keyboard]);

  const focusInput = () => searchRef.current.focus();

  return (
    <Flex
      alignItems="center"
      onClick={focusInput}
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <StyledInput
        ref={searchRef}
        placeholder={props?.placeholder ?? 'Zoek een patient'}
        onChange={({ target: { value } }) => debouncedCb(value)}
      />
      <Icon icon="search" />
    </Flex>
  );
};

export default Searchbar;
