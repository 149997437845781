import styled from 'styled-components';
import { Flex } from '~components';

const Tag = styled(Flex).attrs({ as: 'span' })`
  padding: 6px 12px;

  background-color: ${(p) => p.theme.colors.patient.B600};
  color: ${(p) => p.theme.colors.patient.B900};

  font-size: 12px;
  font-weight: 600;
  line-height: 1;

  width: auto;
  border-radius: ${(p) => p.theme.radii.sm};
`;

export default Tag;
