import { useField } from 'formik';

const useFormikField = (config, message, submitCount) => {
  const [field, meta, helpers] = useField(config);
  const isValid = meta.touched && !meta.error;

  const shouldDisplayError = isNaN(submitCount)
    ? meta.touched && meta.error
    : !!submitCount && meta.error;

  const shouldDisplayMessage = message && !isValid;

  const error = shouldDisplayError ? meta.error : undefined;
  const helperText = shouldDisplayMessage ? message : undefined;

  return {
    field,
    meta,
    helpers,
    isValid,
    error,
    helperText,
  };
};

export default useFormikField;
