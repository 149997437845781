import { useContext } from 'react';
import * as React from 'react';
import Creatable, { Props } from 'react-select/creatable';
import styled, { css, ThemeContext } from 'styled-components';
import { system, ISystem } from '~lib';

interface InputProps {
  isValid?: boolean;
}

const StyledSelect = styled(Creatable).attrs({
  className: 'react-select',
  classNamePrefix: 'react-select',
})<ISystem & InputProps>(
  ({ styledTheme: theme, isValid }) => css`
    ${system}

    font-size: 14px;
    width: 45%;
    flex: 1;

    .react-select {
      max-height: 24px;

      &__menu {
        z-index: 20 !important;
      }

      &__control {
        transition: border-color 0.18s ease;
        border-radius: ${theme.radii.sm};
        box-shadow: none;
        background-color: ${theme.getRoleColor('N200')};

        border: none;
        cursor: pointer;
        padding: 2px 4px;

        ${!isValid &&
        `
          box-shadow: 0 0 1px ${`${theme.colors.R400}`};
        `}

        &:hover,
        &:focus,
        &--is-focused {
          background: ${theme.colors.professional.N400};
          border: none;
        }
      }

      &__single-value {
        margin-right: 0;
        margin-left: 0;
      }

      &__value-container {
        padding: 0;
        justify-content: flex-end;
      }

      &__indicator-separator {
        display: none;
      }

      &__indicators {
        display: none;
      }

      &__menu {
        background: none;
        background-color: ${theme.getRoleColor('N200')};
        width: 100%;
        min-width: fit-content;
      }

      &__input {
        min-width: 30px;
      }

      &__placeholder {
        min-width: fit-content;
        margin: 0;
      }

      &__menu_list {
        background: none;
      }

      &__option {
        color: ${theme.getRoleColor('N600')};
        background: none;

        cursor: pointer;

        &:hover,
        &:focus,
        &--is-focused {
          color: ${theme.getRoleColor('N900')};
          background: ${theme.colors.professional.N400};
        }
      }
    }
  `,
);

export interface ISelect extends Props<any, false> {
  theme?: any;
}

export const Select: React.FC<ISelect> = (props) => {
  const styledTheme = useContext(ThemeContext); // react-select and styled-components both need a theme so it needs to be renamed

  return (
    <StyledSelect
      styledTheme={styledTheme}
      theme={(t) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: styledTheme.getRoleColor('N200'),
          primary50: styledTheme.getRoleColor('N400'),
          primary: styledTheme.getRoleColor('N800'),
        },
      })}
      {...props}
      placeholder="Select one"
    />
  );
};

export default Select;
