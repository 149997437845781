const CASE_STATUS = {
  created: { label: 'Aangemaakt', color: 'O400' },
  open: { label: 'Open', color: 'G400' },
  closed: { label: 'Gesloten', color: 'Y400' },
  canceled: { label: 'Canceled', color: 'Y400' },
  helpRequested: { label: 'Hulp gevraagd', color: 'O400' },
  helpAnswered: { label: 'Hulp beantwoord', color: 'G200' },
  referredToOpto: {
    label: 'Verwezen naar optometrist',
    color: 'O400',
  },
  referredToOphthal: {
    label: 'Verwezen naar ophthalmologist',
    color: 'O400',
  },
  assigned: { label: 'Toegewezen', color: 'G400' },
  unassigned: { label: 'Niet toegewezen', color: 'N400' },
};

export default CASE_STATUS;
