import { useState, useEffect } from 'react';

const useKeyboard = (
  target: string | string[],
  options?: { preventDefault: boolean },
): { currentKey: string; active: boolean } => {
  const [keyPressed, setKeyPressed] = useState({
    currentKey: undefined,
    active: false,
  });

  const downHandler = (event) => {
    if (Array.isArray(target)) {
      if (target.includes(event.key)) {
        if (options?.preventDefault) event.preventDefault();

        return setKeyPressed({ currentKey: event.key, active: true });
      }
    }

    if (event.key === target) {
      return setKeyPressed({ currentKey: event.key, active: true });
    }
  };

  const upHandler = ({ key }) => {
    if (Array.isArray(target)) {
      if (target.includes(key)) {
        if (options?.preventDefault) event.preventDefault();

        return setKeyPressed({ currentKey: key, active: false });
      }
    }

    if (key === target) {
      return setKeyPressed({ currentKey: key, active: false });
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export default useKeyboard;
