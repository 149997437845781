import { FC } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from 'styled-components';
import { Box, Flex } from '~components';

const StyledOverlay = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: ${(p) => p.theme.zIndices.modal};

  width: 100%;
  min-height: 100vh;

  && {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const StyledModal = styled(Flex)`
  position: relative;

  background: white;

  min-width: 512px;
  min-height: 256px;
  margin: auto;
`;

const StyledContent = styled(Flex)`
  flex-direction: column;

  padding: 24px;

  flex: 1;
`;

interface HelpModalProps {
  showHelp: boolean;
  close: () => void;
  children: any;
  className?: string;
}

export const HelpModal: FC<HelpModalProps> = ({
  showHelp,
  close,
  children,
  className,
}) => {
  const AnimatedOverlay = animated(StyledOverlay);
  const AnimatedModal = animated(StyledModal);
  const AnimatedContent = animated(StyledContent);

  const transition = useTransition(showHelp, {
    from: { transform: -100, opacity: 0, display: 'none' },
    enter: { transform: 0, opacity: 1, display: 'flex' },
    leave: { transform: -100, opacity: 0, display: 'none' },
  });

  const handleClick = (e) => {
    if (e.target.id === 'overlay') {
      close();
    }
  };

  return transition(
    (styles, item) =>
      item && (
        <AnimatedOverlay
          onClick={handleClick}
          style={{ opacity: styles.opacity, display: styles.display }}
          id="overlay"
        >
          <AnimatedModal
            className={className}
            style={{
              transform: styles.transform.interpolate(
                (value) => `translate3d(0, ${value}%, 0)`,
              ),
            }}
          >
            <AnimatedContent
              style={{
                opacity: styles.opacity,
              }}
            >
              {children}
            </AnimatedContent>
          </AnimatedModal>
        </AnimatedOverlay>
      ),
  );
};

export default HelpModal;
