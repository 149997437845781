import styled from 'styled-components';

export const Divider = styled.div`
  flex-shrink: 0;

  height: 28px;
  width: 1px;
  background-color: ${(p) => p.theme.getRoleColor('N700', 'N700')};
  margin: 0px 16px;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    height: 1px;
    width: 28px;
    background-color: ${(p) => p.theme.colors.white};
    margin: 16px 0px;
  }
`;
