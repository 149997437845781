import { FC, useState } from 'react';
import includes from 'lodash/includes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import uniqid from 'uniqid';
import { Flex, Text, TransparentButton, Icon } from '~components';
import { useAccount } from '~hooks';
import OrganisationIdentifier from '~components/admin/OrganisationIdentifier';
import {
  Logo,
  Nav,
  MainNav,
  NavLink,
  NavButton,
  Divider,
  HelpModal,
  Tooltip,
} from '.';

const commonLinks = [
  {
    href: '/account',
    text: 'Account',
    icon: [
      { icon: 'user', className: 'hidden-mobile' },
      { icon: 'account', className: 'hidden-desktop' },
    ],
    small: true,
  },
];

const _professionalRoles = ['gp', 'opto', 'ophthal'];

const _links = {
  professional: [
    {
      href: '/patients',
      text: 'Patients',
      icon: 'healthprofessionals',
    },
    { href: '/requests', text: 'Requests', icon: 'help-request' },
    ...commonLinks,
  ],
  superadmin: [
    {
      href: '/admin',
      text: 'Stats',
      icon: 'clipboard',
    },
    {
      href: '/organisations',
      text: 'Organisations',
      icon: 'add-account',
    },
    ...commonLinks,
  ],
  admin: [
    {
      href: '/consult-requests',
      text: 'Consult requests',
      icon: 'healthprofessionals',
    },
    {
      href: '/professionals',
      text: 'Professionals',
      icon: 'add-account',
    },
    {
      href: '/locations',
      text: 'Locations',
      icon: 'location',
    },
    {
      href: '/activatie',
      text: 'Auto activatie',
      icon: 'share',
    },
    ...commonLinks,
  ],
  patient: [
    { href: '/case', text: 'Case', icon: 'my-cases' },
    {
      href: '/permissions',
      text: 'Permissions',
      icon: 'healthprofessionals',
    },
    ...commonLinks,
  ],
};

const getProfessionalLinks = (isSelected) =>
  isSelected
    ? _links.professional
    : _links.professional.filter((item) => item.href !== '/requests');

interface Props {
  role: string;
  title: string;
  withShadow?: boolean;
}

const Navbar: FC<Props> = ({ role, title, withShadow }) => {
  const router = useRouter();
  const { logout, user } = useAccount();
  const [showHelp, setShowHelp] = useState(false);

  const links = _professionalRoles.includes(user?.role)
    ? getProfessionalLinks(user?.isSelectedProfessional)
    : _links[role] || [];

  const toggleHelp = () => setShowHelp(!showHelp);
  const closeHelp = () => setShowHelp(false);

  return (
    <>
      <Nav>
        <Link href="/" passHref>
          <Logo className="hidden-mobile" mb={6} />
        </Link>
        <Text
          fontSize={{ default: 3, sm: 5 }}
          as="h1"
          fontWeight="bold"
          className="hidden-desktop"
          ml={7}
          display="flex"
          alignItems="center"
        >
          {title}
          <OrganisationIdentifier
            pt={1}
            pl={4}
            display={{ sm: 'block', default: 'none' }}
            fontSize={1}
          />
        </Text>
        <MainNav>
          {links?.map(({ href, as, text, icon, ...rest }) => (
            <Link href={href} as={as} passHref key={href}>
              <NavLink isActive={includes(router.route, href)} {...rest}>
                {typeof icon === 'string' ? (
                  <Icon
                    icon={icon}
                    color={{ default: `${role}.B700`, md: `${role}.primary` }}
                    fontSize={5}
                    mb={{ default: 1, sm: 0 }}
                  />
                ) : (
                  icon.map((item) => (
                    <Icon
                      key={uniqid()}
                      color={{ default: `${role}.B700`, md: `${role}.primary` }}
                      fontSize={5}
                      mb={{ default: 1, sm: 0 }}
                      {...item}
                    />
                  ))
                )}
                <Text className="hidden-desktop">{text}</Text>
                <Tooltip>{text}</Tooltip>
              </NavLink>
            </Link>
          ))}
        </MainNav>

        <Flex
          flexDirection={{ default: 'row', md: 'column' }}
          alignItems="center"
        >
          <NavButton
            onClick={toggleHelp}
            as={TransparentButton}
            fontSize={1}
            color={`${role}.N700`}
            minWidth={{ default: '30px', md: 'auto' }}
            mb="0"
          >
            <Tooltip>Help</Tooltip>
            <Icon
              icon="help"
              color={`${role}.primary`}
              fontSize={4}
              className="hidden-mobile"
            />
            <Icon
              icon="contact-support"
              color={`${role}.N700`}
              fontSize={5}
              className="hidden-desktop"
              m="0 auto"
            />
          </NavButton>

          <Divider />

          <NavButton
            onClick={logout}
            as={TransparentButton}
            fontSize={1}
            color={`${role}.N700`}
            mb={0}
            minWidth={{ default: '75px', md: 'auto' }}
          >
            <Tooltip>Logout</Tooltip>
            <Text className="hidden-desktop">Uitloggen</Text>
            <Icon
              icon="logout-mobile"
              color={`${role}.N700`}
              fontSize={4}
              className="hidden-desktop"
            />
            <Icon
              icon="logout"
              color="primary"
              fontSize={4}
              className="hidden-mobile"
            />
          </NavButton>
        </Flex>
      </Nav>
      <HelpModal showHelp={showHelp} close={closeHelp}>
        <Flex justifyContent="center" mt="24px">
          <Text fontWeight="bold" fontSize={2} color="professional.N900">
            Contact support
          </Text>
        </Flex>
        <Flex alignSelf="center" mt="24px">
          <Text fontSize={2}>
            Stel je vraag naar{' '}
            <a
              href="mailto:support@eyehelp.nl"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              support@eyehelp.nl
            </a>{' '}
            en wij komen zo snel mogelijk bij je terug!
          </Text>
        </Flex>
      </HelpModal>
    </>
  );
};

export default Navbar;
