import styled from 'styled-components';
import { Flex } from '~components';

export const MainNav = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  height: 56px;
  width: 100vw;
  background-color: ${(p) => p.theme.colors.white};
  border-top: 1px solid rgba(137, 150, 162, 0.35);

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: 100%;

    border: none;
    background-color: transparent;
  }
`;
