import { useState, useEffect } from 'react';
import * as React from 'react';
import { omit } from '@styled-system/props';
import { Props } from 'react-select';
import { useId } from '~hooks';
import { ISystem } from '~lib';
import StyledCreatable from './components/StyledCreatable';
import StyledSelect from './components/StyledSelect';

export interface ISelect extends ISystem, Props {
  name: string;
  id?: string;
  onChange?: (val?) => any;
  placeholder?: string;
  options: { value: string; label: string; selected?: boolean }[];
  isValid?: boolean;
  value?: any;
}

export const Select: React.FC<ISelect> = ({
  onChange,
  label,
  error,
  message,
  id,
  creatable,
  ...props
}) => {
  const [selected, setSelected] = useState(undefined);
  const autoId = useId();

  useEffect(() => {
    if (selected) {
      const toPass = props.isMulti ? selected : selected.value;
      if (props.setValue) {
        props.setValue(toPass);
      } else {
        onChange(toPass);
      }
    }
  }, [selected]);

  let SelectElement = StyledSelect;

  if (creatable) {
    SelectElement = StyledCreatable;
  }

  const getValue = () => {
    if (props.isMulti) {
      return props.options.filter(({ selected }) => selected);
    } else {
      return (
        selected || props.options.find((option) => option.value === props.value)
      );
    }
  };

  return (
    <SelectElement
      {...omit(props)}
      id={id || autoId}
      inputId={`select-${id || autoId}`}
      placeholder="Selecteer een optie"
      /* @ts-ignore  */
      value={getValue()}
      onChange={(val) => setSelected(val)}
      openOnFocus
    />
  );
};

export default Select;
