import { useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { startsWith } from 'lodash';
import { useRouter } from 'next/router';
import { UserContext } from '~context';
import { Role } from '~lib/constants';
import { handlePromise } from '~lib/helpers';
import { IAccount } from '../lib/abstractions/account';

const useAccount = () => {
  const {
    user,
    role,
    setUser,
    isAuth,
    setIsAuth,
    isLoading,
    setIsLoading,
    isChecking,
  } = useContext(UserContext);
  const isFirstMount = useRef(true);
  const router = useRouter();

  const isLoginRoute =
    startsWith(router.pathname, '/login') ||
    startsWith(router.pathname, '/two-factor');
  const isRegisterRoute =
    startsWith(router.pathname, '/register') ||
    startsWith(router.pathname, '/onboarding/two-factor');
  const isInfoRoute = router.pathname === '/onboarding/personal-information';
  const isForgotPasswordRoute =
    router.pathname === '/forgot-password' ||
    router.pathname === '/reset-password';

  const checkSession = async () => {
    const [err, result] = await handlePromise(axios('/auth'), {
      errorMsg: false,
    });

    if (err) {
      setUser(undefined);
      setIsLoading(false);
      isFirstMount.current = false;
      isChecking.current = false;

      if (
        !isLoginRoute &&
        !isRegisterRoute &&
        !isInfoRoute &&
        !isForgotPasswordRoute
      ) {
        router.push('/login');
      }
      return;
    }

    if (result) {
      if (result?.account) {
        setIsAuth(true);
        const userObject = result?.account;

        setUser(userObject);
      }

      setIsLoading(false);
      isFirstMount.current = false;
      isChecking.current = false;
    }
  };

  const handleRedirect = (
    type: '2fa' | 'onboarding' | 'dashboard',
    user: IAccount,
  ) => {
    let redirectTo = '';

    if (type === '2fa') {
      if (
        !startsWith(router.pathname, '/onboarding/two-factor') &&
        !startsWith(router.pathname, '/two-factor')
      ) {
        redirectTo = '/onboarding/two-factor';
      }
    }

    if (type === 'onboarding') {
      redirectTo = '/onboarding/personal-information';
    }

    if (type === 'dashboard') {
      if (isLoginRoute || isRegisterRoute || (isInfoRoute && user?.fullName)) {
        if (user.role === Role.SuperAdmin) {
          redirectTo = '/admin';
        } else if (user.role === Role.Patient) {
          redirectTo = '/case';
        } else if (user.role === Role.Admin) {
          redirectTo = '/consult-requests';
        } else if (
          [Role.GP, Role.Ophthalmologist, Role.Optometrist].includes(user.role)
        ) {
          redirectTo = '/patients';
        }
      }
    }

    if (!!redirectTo) {
      router.push(redirectTo);
    }

    return;
  };

  useEffect(() => {
    if (isFirstMount.current && !isChecking.current && (!isAuth || !user)) {
      isChecking.current = true;
      checkSession();
    }
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      !isFirstMount.current &&
      !isChecking.current &&
      (!isAuth || !user)
    ) {
      isChecking.current = true;
      checkSession();
    }
  }, [isAuth, user]);

  useEffect(() => {
    if (user) {
      if (user.verifiedPhone) {
        handleRedirect('dashboard', user);
        return;
      }

      // if phone is not verified or not existing redirect to phone stuff
      if (!user?.phone || !user?.verifiedPhone) {
        handleRedirect('2fa', user);
        return;
      }

      // if phone is not verified or not existing redirect to phone stuff
      if ((!user?.firstName || !user?.lastName) && !isInfoRoute) {
        handleRedirect('onboarding', user);
        return;
      }
    }
  }, [isAuth, user]);

  const logout = async () => {
    await handlePromise(axios.delete('/auth'), { errorMsg: false });

    setIsAuth(false);
    setUser(undefined);

    router.push('/login');
  };

  return {
    isAuth,
    setIsAuth,
    setUser,
    user,
    role,
    isLoading,
    logout,
  };
};

export default useAccount;
