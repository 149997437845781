import * as React from 'react';
import styled from 'styled-components';
import { system, ISystem } from '~lib';

const Wrapper = styled.label<ISystem>`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;

  /* Hide default HTML checkbox */
  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  > .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  > .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
    transition: all 0.25s cubic-bezier(0.09, 1.08, 0.77, 1.18);
  }

  > input:checked + .slider {
    background-color: ${(p) => p.theme.colors.professional.secondary};
  }

  > input:focus + .slider {
    box-shadow: 0px 0px 0px 3px ${(p) => p.theme.colors.professional.primary};
  }

  > input:checked + .slider:before {
    transform: translateX(20px);
  }

  ${system}
`;

export interface IToggle extends ISystem {
  checked?: boolean;
  onChange?(val: boolean): void;
  setValue?(name: string, val: boolean): void;
  value: boolean | undefined;
  props?: any;
  fieldProps?: any;
}

export const Toggle: React.FC<IToggle> = ({
  checked,
  onChange,
  setValue,
  value,
  ...props
}) => {
  const handleChange = (e) => {
    if (setValue) {
      return setValue((props as any)?.name, e.target.checked);
    }

    onChange(e.target.checked);
  };

  return (
    <Wrapper>
      {/* @ts-ignore */}
      <input
        type="checkbox"
        checked={value}
        onChange={handleChange}
        {...props}
      />
      <span className="slider" />
    </Wrapper>
  );
};

export default Toggle;
