import { LabelHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Box } from '~components';

const StyledLabel = styled(Box).attrs({ as: 'label' })<
  LabelHTMLAttributes<HTMLLabelElement>
>`
  padding-bottom: 8px;
  font-size: ${(p) => p.theme.fontSizes[0]};
  font-weight: bold;
  text-transform: uppercase;
  color: ${(p) => p.theme.getRoleColor('N600', 'N700')};
  letter-spacing: 0.03em;
`;

export default StyledLabel;
