import styled from 'styled-components';
import { variant } from 'styled-system';
import { Box } from '~components';

export type TextProps = {
  variant?: 'ellipsis' | 'uppercase';
};

const Text = styled(Box)<TextProps>`
  line-height: 100%;

  ${variant({
    variants: {
      ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      uppercase: {
        textTransform: 'uppercase',
      },
    },
  })}
`;

export default Text;
