import styled from 'styled-components';
import { system, ISystem } from '~lib';

interface CheckboxInputProps extends ISystem {
  error?: string;
}

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})<CheckboxInputProps>`
  appearance: none;
  position: relative;
  margin-right: ${(p) => p.theme.space[3]}px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: ${(p) => p.theme.radii.sm};
  border: 1px solid
    ${(p) => (p.error ? p.theme.colors.R400 : p.theme.colors.white)};
  box-shadow: 0px 0px 0px 1px ${(p) => p.theme.getRoleColor('N400')};
  background-color: ${(p) => p.theme.getRoleColor('N300')};
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 0px 1px ${(p) => p.theme.getRoleColor('primary')};
  }

  &:checked {
    background-color: ${(p) => p.theme.getRoleColor('N700')};
  }

  ${system}
`;

export default CheckboxInput;
