import { Flex } from '~components';

const Row = ({ children, ...props }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p="8px 16px 8px 24px"
      bg="professional.N200"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Row;
