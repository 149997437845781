import styled from 'styled-components';

interface HelperProps {
  type?: string;
  isDisplayed: boolean;
}

const StyledHelper = styled.div<HelperProps>`
  position: absolute;
  margin-top: 4px;
  opacity: ${(p) => (p.isDisplayed ? 1 : 0)};
  font-size: ${(p) => p.theme.fontSizes[0]};
  color: ${(p) =>
    p.type === 'error' ? p.theme.colors.R400 : p.theme.getRoleColor('N700')};
  transition: opacity 0.25s ease-in-out;
`;

export default StyledHelper;
