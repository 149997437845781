import { FC } from 'react';
import styled from 'styled-components';
import { Flex, Text } from '~components';

const getColor = (type) => {
  let color = '#29A37B';

  if (type === 'warning') {
    color = '#E5AD00';
  } else if (type === 'error') {
    color = '#FB4737';
  }

  return color;
};

const StyledToastAlert = styled(Flex)<{ type: string }>`
  align-items: center;

  /* professional primary */
  background-color: ${({ type }) => getColor(type)};

  min-height: 48px;

  padding: 0 24px;
`;

interface IToast {
  msg: string;
  closeToast(): void;
  type?: string;
}

const ToastAlert: FC<IToast> = ({ msg, closeToast, type = 'success' }) => (
  <StyledToastAlert type={type}>
    <Text color="white" fontSize={1}>
      {msg}
    </Text>
  </StyledToastAlert>
);

export default ToastAlert;
