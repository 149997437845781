import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Box } from '~components';
import { ISystem } from '~lib';

const CardWrapper = styled(Box)<{ ratio: number }>`
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: ${(p) => p.ratio || 100}%;
  }
`;

const CardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface Props {
  baseH: number;
  baseW: number;
  children: ReactNode;
}

const FixedRatio: FC<Props & ISystem> = ({
  baseH,
  baseW,
  children,
  ...props
}) => {
  const ratio = Number(((baseH / baseW) * 100).toFixed(2));

  return (
    <CardWrapper ratio={ratio} {...props}>
      <CardContent>{children}</CardContent>
    </CardWrapper>
  );
};

export default FixedRatio;
