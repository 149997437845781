import styled from 'styled-components';
import { Flex, Text } from '~components';

const getColor = (colors, type) => {
  let color = colors.professional.N400;

  // get different colors based on the type value
  // if (type === 'hasConsent') color = colors.G400

  return color;
};

const StyledStatusLabel = styled(Flex)<{ type: string }>`
  background: ${({ type, theme: { colors } }) => getColor(colors, type)};

  border-radius: 4px;

  padding: 6px 12px;
  width: auto !important;
  line-height: 1;
`;

const StatusLabel = ({ type: { value, label } }) => {
  return (
    <StyledStatusLabel type={value}>
      <Text fontWeight={600} color="professional.N900" fontSize={1}>
        {label}
      </Text>
    </StyledStatusLabel>
  );
};

export default StatusLabel;
