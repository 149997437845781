import { FC, useEffect, ReactNode } from 'react';
import { findIndex, orderBy } from 'lodash';
import styled from 'styled-components';
import { Flex, Text, Sidebar } from '~components';
import { useKeyboard } from '~hooks';
import { formatDate } from '~lib/helpers';

interface IHistory {
  consultations: Record<string, any>[];
  consultation: { id: string };
  setConsult({ id: string }): void;
  children?: ReactNode;
}

const Section = styled(Flex)<{ isActive: boolean; isHelpConsult?: boolean }>`
  justify-content: space-between;

  cursor: pointer;

  background: ${(p) => p.theme.colors.professional.N400};
  border: 2px solid transparent;
  border-radius: 4px;

  padding: ${(p) => (p.isActive ? '16px 12px' : '8px 12px 10px 12px')};

  transition: all 0.18s ease-in-out;

  &:hover {
    border: 2px solid ${(p) => p.theme.colors.professional.N900};
  }

  &:focus {
    background: ${(p) => p.theme.colors.professional.N500};
  }

  ${(p) =>
    p.isActive &&
    `
      border: 2px solid ${p.theme.colors.professional.N900};
  `}

  ${(p) =>
    p.isHelpConsult &&
    !p.isActive &&
    `
      background-color: ${p.theme.colors.professional.N500};
      animation-name: color;
      animation-duration: 2s;
      animation-iteration-count: infinite;

      @keyframes color {
      0% {
        background-color: ${p.theme.colors.professional.N500};
      }
      50% {
        background-color:${p.theme.colors.professional.N400};
      }
      100 {
        background-color: ${p.theme.colors.professional.N500};
      }
    }
  `}
`;

const renderConsultations = (consultations, consultation, setConsult) =>
  orderBy(consultations, ['createdAt'], ['desc'])?.map((c, key) => (
    <Section
      as="button"
      mb="8px"
      isActive={c?.id === consultation?.id}
      key={key}
      onClick={() => setConsult({ id: c?.id })}
      isHelpConsult={!!c.requestHelpQuestion}
    >
      <Text fontSize={1} color="professional.N900" lineHeight="150%">
        {c?.createdAt && formatDate(c?.createdAt, 'yyyy.MM.dd')}
      </Text>
      <Text
        fontWeight="bold"
        fontSize={1}
        color="professional.N900"
        lineHeight="150%"
      >
        Consult
      </Text>
    </Section>
  ));

const HistorySidebar: FC<IHistory> = ({
  consultations,
  consultation,
  setConsult,
  children,
}) => {
  const keyboard = useKeyboard(['PageUp', 'PageDown']);

  const navigateCases = (direction) => {
    if (consultation) {
      const currentIndex = findIndex(consultations, consultation);

      if (direction === 'down' && currentIndex < consultations.length - 1) {
        setConsult({ id: consultations[currentIndex + 1].id });
      }

      if (direction === 'up') {
        if (currentIndex > 0) {
          setConsult({ id: consultations[currentIndex - 1].id });
        } else {
          setConsult(null);
        }
      }
    } else if (direction === 'down') {
      setConsult({ id: consultations[0].id });
    }
  };

  useEffect(() => {
    if (keyboard.active) {
      if (keyboard.currentKey === 'PageUp') {
        navigateCases('up');
      }

      if (keyboard.currentKey === 'PageDown') {
        navigateCases('down');
      }
    }
  }, [keyboard]);

  return (
    <Sidebar>
      <Flex height="100%" justifyContent="space-between" flexDir="column">
        <Flex flexDir="column" height="100%" overflowY="auto" mb="12px">
          <Text
            fontSize={4}
            fontWeight="bold"
            color="professional.N900"
            mb="28px"
          >
            Tijdlijn
          </Text>
          <Section
            as="button"
            mb="32px"
            onClick={() => setConsult(null)}
            isActive={!consultation}
            width={1}
          >
            <Text
              fontWeight="bold"
              fontSize={1}
              color="professional.N900"
              lineHeight="150%"
            >
              Overview
            </Text>
          </Section>
          <Flex flexDirection="column">
            <Text
              fontSize={2}
              fontWeight="bold"
              color="professional.N600"
              mb="12px"
            >
              Consultations
            </Text>
            {renderConsultations(consultations, consultation, setConsult)}
          </Flex>
        </Flex>
        {children}
      </Flex>
    </Sidebar>
  );
};

export default HistorySidebar;
