import { FC, useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Icon } from '~components';
import { useKeyboard } from '~hooks';

const Aside = styled.div`
  position: relative;
  padding: 24px;

  width: 240px;
  flex-shrink: 0;

  background: #fff;
  border-right: 1px solid #c8dbe0;

  z-index: 14;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    height: calc(100vh - 56px);
  }
`;

const ToggleSidebar = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 24px;
  right: -12px;

  height: 24px;
  width: 24px;

  border-radius: 100%;
  background: ${(p) => (p.isOpen ? '#c8dbe0' : '#F3F8FA')};

  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);

  div > svg {
    fill: #c8dbe0;
  }
`;

const Content = styled.div`
  height: 100%;
`;

const Sidebar: FC = ({ children }) => {
  const AnimatedSidebar = animated(Aside);
  const AnimatedIcon = animated(Icon);
  const AnimatedContent = animated(Content);

  const keyboard = useKeyboard(['Alt'], { preventDefault: true });
  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  useEffect(() => {
    if (keyboard.active && keyboard.currentKey === 'Alt') {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboard.active]);

  const springValues = showSidebar
    ? { width: 240, x: 0, rotate: -180 }
    : { width: 48, x: -240, rotate: 0 };

  const springProps = useSpring({
    ...springValues,
  });

  return (
    <AnimatedSidebar
      style={{
        width: springProps.width.interpolate((value) => `${value}px`),
      }}
    >
      <ToggleSidebar onClick={toggleSidebar} isOpen={showSidebar}>
        <AnimatedIcon
          icon="chevron-right"
          style={{
            transform: springProps.rotate.interpolate(
              (value) => `rotate(${value}deg)`,
            ),
          }}
        />
      </ToggleSidebar>
      <AnimatedContent
        style={{
          transform: springProps.x.interpolate(
            (value) => `translate3d(${value}px, 0,0)`,
          ),
        }}
      >
        {children}
      </AnimatedContent>
    </AnimatedSidebar>
  );
};

export default Sidebar;
