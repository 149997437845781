import * as React from 'react';
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
} from '@reach/dialog';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';

const DialogOverlay = styled(ReachDialogOverlay)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: ${(p) => p.theme.zIndices.popover};

  width: 100%;
  min-height: 100vh;

  && {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const DialogContent = styled(ReachDialogContent)`
  position: absolute;
  top: 0;
  bottom: 0;

  height: 100vh;
  width: 100%;

  && {
    padding: 0;
    background: white;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    width: 70%;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    width: 55%;
  }
`;

export interface IModal {
  isOpen: boolean;
  close: () => void;
  height?: any;
  width?: any;
  size?: any;
  aria?: string;
}

const Modal: React.FC<IModal> = ({
  isOpen,
  close,
  children,
  aria,
  ...props
}) => {
  const AnimatedDialogOverlay = animated(DialogOverlay);
  const AnimatedDialogContent = animated(DialogContent);

  const transition = useTransition(isOpen, {
    from: { opacity: 0, x: 155 },
    enter: { opacity: 1, x: 82 },
    leave: { opacity: 0, x: 200 },
  });

  return transition(
    (styles, item) =>
      item && (
        <AnimatedDialogOverlay
          onDismiss={close}
          style={{ opacity: styles.opacity }}
        >
          <AnimatedDialogContent
            {...props}
            aria-label={aria || 'dialog content'}
            style={{
              transform: styles.x.interpolate(
                (value) => `translate3d(${value}%, 0, 0)`,
              ),
            }}
          >
            {children}
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      ),
  );
};

export default Modal;
