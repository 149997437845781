import styled from 'styled-components';
import { Flex } from '~components';

interface INavItem {
  isActive?: boolean;
  bg?: string;
  small?: boolean;
}

export const Tooltip = styled(Flex).attrs({ as: 'span' })`
  display: none;

  position: absolute;
  left: 45px;

  padding: 8px;

  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: ${(p) => p.theme.getRoleColor('N900')};
  white-space: nowrap;

  background-color: white;
  box-shadow: ${(p) => p.theme.shadows.sm};
`;

export const NavItem = styled.a<INavItem>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  font-size: 12px;
  height: 100%;
  width: 30%;
  min-width: 84px;
  padding: 8px 0;
  margin-bottom: ${(p) => p.theme.space[0]};
  transition: all 0.2s ease;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${(p) => p.theme.space[3]}px;
    padding: 0;
    height: ${(p) => (p.small ? 32 : 38)}px;
    width: ${(p) => (p.small ? 32 : 38)}px;
    border-radius: ${(p) => (p.small ? 32 : 38)}px;
    border: 2px solid ${(p) => p.theme.getRoleColor('primary', 'primary')};
    background-color: ${(p) => p.theme.colors.white};
    opacity: 0.5;
    position: relative;

    min-width: auto;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px ${(p) => p.theme.colors.white};
      opacity: 1;

      ${Tooltip} {
        display: block;
      }
    }
  }
`;
