import { FC, ReactNode } from 'react';
import { Flex, Box } from '~components';
import { HelperText, Label } from '.';

export interface ILayout {
  name: string;
  label?: string;
  error?: string;
  message?: string;
  children?: ReactNode;
}

const Layout: FC<ILayout> = ({
  name,
  label,
  children,
  error,
  message,
  ...props
}) => {
  return (
    <Flex flexDirection="column" {...props}>
      <Label htmlFor={name}>{label}</Label>
      <Box position="relative">
        {children}
        <HelperText
          isDisplayed={!!(error || message)}
          type={error ? 'error' : ''}
        >
          {error || message}
        </HelperText>
      </Box>
    </Flex>
  );
};

export default Layout;
