import { FC, ComponentType, HTMLProps, ReactNode, forwardRef } from 'react';

import { TextInput } from '~components';
import { useFormikField } from '~hooks';
import { ISystem } from '~lib';

type FieldComponent = Omit<HTMLProps<HTMLInputElement>, 'as' | 'ref' | 'width'>;

interface FieldProps extends FieldComponent {
  as?: ComponentType<any>;
  id?: string;
  icon?: string;
  message?: string;
  fieldProps?: any;
  submitCount?: number | undefined;
  children?: ReactNode;
}

const FormikField: FC<FieldProps & ISystem> = forwardRef(
  (
    { as: asElement, message, submitCount, id, fieldProps, children, ...props },
    ref,
  ) => {
    const name = props.name;
    const { field, helpers, helperText, error, isValid } = useFormikField(
      name,
      children || message,
      submitCount,
    );
    const Element: any = asElement || TextInput;

    return (
      <Element
        id={id || name}
        isValid={isValid}
        error={error}
        message={helperText}
        ref={ref}
        {...field}
        {...helpers}
        {...props}
        {...fieldProps}
      />
    );
  },
);

export default FormikField;
