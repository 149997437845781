import styled from 'styled-components';
import { Box } from '~components';

interface ICard {
  shadow?: string;
}

const Card = styled(Box)<ICard>`
  padding: ${(p) => p.theme.space[5]}px;
  background-color: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.patient.N900};
  font-size: ${(p) => p.theme.fontSizes[6]};
  font-weight: 300;
  border-radius: ${(p) => p.theme.radii.sm};
  ${(p) =>
    p.shadow === 'large' &&
    `
    box-shadow: 0px 0.940867px 2.21381px rgba(0, 0, 0, 0.01), 0px 2.26103px 5.32008px rgba(0, 0, 0, 0.01), 0px 4.25733px 10.0172px rgba(0, 0, 0, 0.01), 0px 7.59435px 17.869px rgba(0, 0, 0, 0.02), 0px 14.2044px 33.4221px rgba(0, 0, 0, 0.03), 0px 34px 80px rgba(0, 0, 0, 0.04);
  `}

  ${(p) =>
    p.shadow === 'small' &&
    `
    box-shadow: 0px 0.940867px 2.21381px rgba(0, 0, 0, 0.01), 0px 2.26103px 5.32008px rgba(0, 0, 0, 0.01), 0px 4.25733px 10.0172px rgba(0, 0, 0, 0.01), 0px 7.59435px 17.869px rgba(0, 0, 0, 0.02), 0px 14.2044px 33.4221px rgba(0, 0, 0, 0.03), 0px 10px 10px rgba(0, 0, 0, 0.04);
  `}
`;

export default Card;
