import { forwardRef, HTMLProps } from 'react';
import styled from 'styled-components';
import { Flex } from '~components';
import { ISystem } from '~lib';

const StyledLabel = styled.label`
  font-size: ${(p) => p.theme.fontSizes[0]};
  text-transform: uppercase;
`;
interface Props extends ISystem {
  isValid?: boolean;
  textLeft?: { text: string; padding: string };
  mask?: any;
  maskOptions?: any;
}

const Input = forwardRef(
  (
    { label = '', name, ...props }: Props & HTMLProps<HTMLInputElement>,
    ref: any,
  ) => {
    return (
      <Flex>
        {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
        <input name={name} {...props} ref={ref} />
      </Flex>
    );
  },
);

export default Input;
