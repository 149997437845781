import { FC, ReactNode, HTMLProps } from 'react';
import { pick, omit } from '@styled-system/props';
import { Flex, Text, Layout } from '~components';
import { ISystem } from '~lib';
import StyledCheckbox from './components/StyledCheckbox';

interface ICheckbox extends ISystem {
  id?: string;
  children?: ReactNode;
  error?: string;
  message?: string;
}

const Checkbox: FC<ICheckbox & HTMLProps<HTMLInputElement>> = ({
  label,
  children,
  error,
  message,
  ...props
}) => {
  return (
    <Layout {...pick(props)} error={error} message={message}>
      <Flex alignItems="center">
        <StyledCheckbox error={error} {...omit(props)} />
        {!!label && (
          <Text as="label" htmlFor={props.id} fontSize={1}>
            {label}
          </Text>
        )}
        {children}
      </Flex>
    </Layout>
  );
};

export default Checkbox;
