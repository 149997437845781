import styled from 'styled-components';
import { NavItem } from './NavItem';

export const NavButton = styled(NavItem).attrs({ small: true })`
  flex-direction: row;
  min-width: auto;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    ${(p) =>
      p.bg &&
      `
    background-image: url(${p.bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}
  }
`;
