import { FC, useEffect } from 'react';

import { Text, Sidebar, Checkbox } from '~components';
import { useSelection } from '~hooks';
import { CASE_STATUS } from '~lib/constants';

interface Props {
  setFilters(value: string[]): void;
}

const FilterSidebar: FC<Props> = ({ setFilters }) => {
  const [filters, toggleFilter, isSelected] = useSelection();

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  return (
    <Sidebar>
      <Text fontSize={4} fontWeight="bold" color="professional.N900" mb={6}>
        Filters
      </Text>

      <Text fontSize={2} fontWeight="bold" color="professional.N600" mb={3}>
        Status
      </Text>
      {Object.keys(CASE_STATUS).map((key) => (
        <Checkbox
          label={CASE_STATUS[key].label}
          value={key}
          onChange={() => toggleFilter(key)}
          checked={isSelected(key)}
          name={key}
          id={key}
          key={key}
        />
      ))}
    </Sidebar>
  );
};

export default FilterSidebar;
