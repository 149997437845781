import { noop } from 'lodash';

const getPage = (offset, setOffset, page) => ({
  active: offset === page,
  value: page,
  label: page + 1,
  onClick: () => setOffset(page),
});

const renderDots = (pages, offset, setOffset) => {
  const pagination = [];

  if (
    offset === 0 ||
    offset === 1 ||
    offset === pages.length - 1 ||
    offset === pages.length - 2
  ) {
    // dots should be placed in the center
    const firstPages = pages.slice(0, 2);
    const lastPages = pages.slice(pages.length - 2, pages.length);

    firstPages.forEach((page) => {
      pagination.push(getPage(offset, setOffset, page));
    });

    pagination.push({
      active: false,
      value: undefined,
      label: '...',
      onClick: noop,
    });

    lastPages.forEach((page) => {
      pagination.push(getPage(offset, setOffset, page));
    });
  } else {
    // dots should be placed before and after the current controls
    pagination.push(getPage(offset, setOffset, 0));

    pagination.push({
      active: false,
      value: undefined,
      label: '...',
      onClick: noop,
    });

    const currentPages = pages.slice(offset - 1, offset + 2);
    currentPages.forEach((page) => {
      pagination.push(getPage(offset, setOffset, page));
    });

    pagination.push({
      active: false,
      value: undefined,
      label: '...',
      onClick: noop,
    });

    pagination.push(getPage(offset, setOffset, pages.length - 1));
  }

  return pagination;
};

export const usePagination = ({ offset, setOffset, limit, total = 0 }) => {
  let pagination = []; // visible page numbers and shenanigans
  const pages = []; // actual page numbers based on the total and limit

  for (let i = 0; i * limit <= total; i++) {
    pages.push(i);
  }

  if (pages.length > 4) {
    pagination = renderDots(pages, offset, setOffset);
  } else {
    pages.forEach((page) => {
      pagination.push(getPage(offset, setOffset, page));
    });
  }

  // previous button
  pagination.unshift({
    type: 'icon',
    icon: 'chevron-left',
    onClick: () => setOffset(offset - 1),
    disabled: offset === 0,
  });

  // next button
  pagination.push({
    type: 'icon',
    icon: 'chevron-right',
    onClick: () => setOffset(offset + 1),
    disabled: offset === pages[pages.length - 1],
  });

  return pagination;
};

export default usePagination;
