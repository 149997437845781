function clean(o) {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clean(o[k]);

    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
}

export const removeEmpty = (values) => {
  const emptyDiff = values;

  return emptyDiff;
};
