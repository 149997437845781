import { createContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { includes } from 'lodash';
import { handlePromise } from '~lib/helpers';

interface IContext {
  config: {
    consultation: any[];
  };
  addedConfig: string[];
  defaultConfig: string[];
  setAddedConfig(value: any): void;
  setDefaultConfig(value: any): void;
  addOrRemove(value: string): void;
}

interface Props {
  children: ReactNode;
}

const ConfigContext = createContext<IContext | undefined>(undefined);

const getConfig = async (setConfig) => {
  const [err, result] = await handlePromise(axios.get('/config'), {
    errorMsg: false,
  });

  if (err) {
    console.log('🔥 Config get error:', err);
  }

  return setConfig(result);
};

const ConfigProvider = ({ children }: Props) => {
  const [config, setConfig] = useState(null);
  const [addedConfig, setAddedConfig] = useState([]);
  const [defaultConfig, setDefaultConfig] = useState([]);

  useEffect(() => {
    getConfig(setConfig);
  }, []);

  const addOrRemove = (key: string) => {
    if (includes(addedConfig, key)) {
      setAddedConfig(addedConfig.filter((v) => v !== key));
    } else {
      setAddedConfig([...addedConfig, key]);
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        addedConfig,
        setAddedConfig,
        addOrRemove,
        defaultConfig,
        setDefaultConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, ConfigContext };
