import { map, startsWith } from 'lodash';
import { formatDateFromString } from './formatDate';

const createFormData = (values: any) => {
  const formData = new FormData();

  map(values, (v, key) => {
    if (key === 'profileImage') {
      formData.append(key, v, v.name);
    } else if (key === 'phone') {
      if (v) {
        let phone = v;
        if (!startsWith(v, '+31')) {
          phone = `+31${v}`;
        }
        formData.append(key, phone);
      }
    } else if (key === 'birthDate') {
      if (v) {
        const newDate = formatDateFromString(v);
        formData.append(key, newDate);
      }
    } else if (!!v && v.constructor === Object) {
      map(v, (value, k) => {
        if (value) {
          formData.append(`${key}[${k}]`, value);
        }
      });
    } else if (typeof v !== 'undefined') {
      formData.append(key, v);
    }
  });

  return formData;
};

export default createFormData;
