import { noop } from 'lodash';
import { DefaultTheme } from 'styled-components';

export const patientColors = {
  primary: '#00518C',
  secondary: '#007ABF',
  B900: '#0A2E48',
  B800: '#2A5871',
  B700: '#6289A6',
  B600: '#8EC4E3',
  B500: '#E1EFFB',
  B400: '#F3FAFE',
  N900: '#2C353E',
  N800: '#5A636E',
  N700: '#8996A2',
  N600: '#EAEFF4',
  N400: '#C8DBE0',
  N300: '#F3F8FA',
  N200: '#F5F9FA',
};

export const professionalColors = {
  primary: '#184654', // primary
  secondary: '#259FA7', // secondary
  B900: '#184654', // primary
  B800: '#3C6471',
  B700: '#6F878E',
  B600: '#259FA7', // secondary
  B500: '#8CDEE4',
  B400: '#A3F4F9',
  B300: '#C1FAFD',
  B200: '#E3FBFD',
  N900: '#233C43',
  N800: '#36484E',
  N700: '#4E6369',
  N600: '#61747A',
  N500: '#ADC5CD',
  N400: '#C8DBE0',
  N300: '#F3F8FA',
  N200: '#F5F9FA',
};

const theme: DefaultTheme = {
  // colors
  colors: {
    white: '#FFFFFF',
    patient: {
      ...patientColors,
    },
    professional: {
      ...professionalColors,
    },
    G800: '#18624A',
    G400: '#29A37B',
    G200: '#ADEBD6',
    O800: '#8F4800',
    O400: '#E5AD00',
    O200: '#FFE1C2',
    R800: '#990D00',
    R400: '#FB4737',
    R200: '#FFC7C2',
    Y800: '#936710',
    Y400: '#FFD500',
    Y200: '#FFEE99',
    transparent: 'rgba(255, 255, 255, 0);',
  },
  // typography
  fontWeights: {
    regular: 'normal',
    medium: 500,
    bold: 800,
  },
  fonts: {
    heading: `Inter`,
    body: `Inter`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  fontSizes: {
    root: `calc(16px + 0.2vw)`,
    0: '10px',
    1: '12px',
    2: '14px',
    3: '16px',
    4: '18px',
    5: '20px',
    6: '24px',
    7: '32px',
    8: '40px',
    9: '48px',
    heading: '32px',
  },
  // sizes
  space: {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    5: 24,
    6: 32,
    7: 48,
    8: 64,
    9: 80,
    10: 120,
    11: 160,
    mobileGutter: 16,
  },
  sizes: {
    maxWidth: 1140,
  },
  breakpoints: ['768px', '1024px', '1280px', '1440'],
  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  radii: {
    none: '0',
    sm: '4px',
    md: '8px',
    lg: '18px',
    full: '9999px',
  },
  borders: {
    none: 0,
    '1px': '1px solid',
    '2px': '2px solid',
    '4px': '4px solid',
  },

  shadows: {
    sm: '0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)',
    normal:
      '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 10px 20px rgba(0, 0, 0, 0.08)',
    big: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1)',
    primary:
      '0px 10px 16px rgba(240, 96, 57, 0.16), 0px 4px 6px rgba(0, 0, 0, 0.06)',
    card:
      '0px 0.940867px 2.21381px rgba(0, 0, 0, 0.01), 0px 2.26103px 5.32008px rgba(0, 0, 0, 0.01), 0px 4.25733px 10.0172px rgba(0, 0, 0, 0.01), 0px 7.59435px 17.869px rgba(0, 0, 0, 0.02), 0px 14.2044px 33.4221px rgba(0, 0, 0, 0.03), 0px 10px 10px rgba(0, 0, 0, 0.04)',
    container:
      '0px 0.940867px 2.21381px rgba(0, 0, 0, 0.01), 0px 2.26103px 5.32008px rgba(0, 0, 0, 0.01), 0px 4.25733px 10.0172px rgba(0, 0, 0, 0.01), 0px 7.59435px 17.869px rgba(0, 0, 0, 0.02), 0px 14.2044px 33.4221px rgba(0, 0, 0, 0.03), 0px 34px 80px rgba(0, 0, 0, 0.04)',
    none: 'none',
  },
  getRoleColor: noop as any,
};

theme.breakpoints.xxs = '360px';
theme.breakpoints.xs = '640px';
theme.breakpoints.sm = '768px';
theme.breakpoints.md = '1024px';
theme.breakpoints.lg = '1280px';
theme.breakpoints.xl = '1440px';
theme.breakpoints.xxl = '1600px';

export default theme;
