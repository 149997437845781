import { FC, ReactNode, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Flex, Button, UnderlineButton } from '~components';

const StyledImageUpload = styled(Flex)`
  align-items: center;
  justify-content: center;

  height: 200px;
  min-width: 200px;
`;

const RoundContainer = styled(Flex)`
  display: none;

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    display: block;
  }

  align-self: center;
  min-width: 200px;
  min-height: 201px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.2);
`;

const UploadButtonContainer = styled(Flex)`
  flex-direction: column;
  position: absolute;

  top: calc(100% + 16px);
  left: 0;
  width: 100%;
  align-items: center;
`;

const ImageUpload: FC<{
  handleDrop(files: File[]): void;
  removeUpload?: () => void;
  hasPreview?: boolean;
  dropzoneConfig?: any;
  showRestraints?: boolean;
  withButtons?: boolean;
  customButton?: ReactNode;
  disabled?: boolean;
}> = ({
  handleDrop,
  removeUpload,
  hasPreview,
  customButton = null,
  dropzoneConfig = {},
  withButtons = false,
  showRestraints = false,
  disabled = false,
}) => {
  const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [
    handleDrop,
  ]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneConfig,
  });

  const handleRemoval = (e) => {
    e.stopPropagation();

    removeUpload();
  };

  return (
    <StyledImageUpload {...getRootProps()}>
      <input {...getInputProps()} disabled={disabled} />
      {showRestraints && <RoundContainer />}
      {hasPreview ? null : customButton}
      {withButtons && (
        <UploadButtonContainer>
          <Button
            mb={{ default: '8px', lg: 0 }}
            mr={{ lg: '8px' }}
            bg="transparent"
            color="patient.N800"
            border="1px"
            borderColor="patient.N800"
          >
            Upload foto
          </Button>
          {hasPreview && !!removeUpload && (
            <UnderlineButton onClick={handleRemoval} p={3}>
              Verwijder foto
            </UnderlineButton>
          )}
        </UploadButtonContainer>
      )}
    </StyledImageUpload>
  );
};

export default ImageUpload;
