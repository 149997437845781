import { FC } from 'react';

import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { Spinner } from '~components';
import { useAccount } from '~hooks';
import { Role } from '../../../lib/constants/roles';

interface IProtectedRoute {
  type?: string;
}

const checkIsLoading = (isLoading, user, router) => {
  if (isLoading || !user || !user?.role) {
    return true;
  }

  if (user && (!user.verifiedPhone || !user.fullName)) {
    /** @note probably should redirect the user somewhere */
    // it's also not loading just missing info
    router.replace('/');
    return true;
  }

  return false;
};

const isPatient = (role) => role === Role.Patient;
const isAdmin = (role) => role === Role.Admin;
const isSuperAdmin = (role) => role === Role.SuperAdmin;
const isProf = (role) =>
  [Role.Ophthalmologist, Role.Optometrist, Role.GP].includes(role);

const hasAuthorization = (type, user, pathname) => {
  if (type === 'patient') {
    return isPatient(user.role);
  }

  if (type === 'professional') {
    return (
      (pathname.startsWith('/requests') && user?.isSelectedProfessional) ||
      isProf(user.role)
    );
  }

  if (type === 'admin') {
    return isAdmin(user.role);
  }

  if (type === 'superadmin') {
    return isSuperAdmin(user.role);
  }
};

const ProtectedRoute: FC<IProtectedRoute> = ({ type, children }) => {
  const { user, isLoading } = useAccount();
  const router = useRouter();

  const renderRoute = () => {
    // console.log('loading', { type, user, isLoading });
    if (checkIsLoading(isLoading, user, router))
      return (
        <>
          <NextSeo title="Loading" />
          <Spinner height="100vh" />
        </>
      );

    if (!hasAuthorization(type, user, router.pathname)) {
      return router.replace('/404');
    }

    return children;
  };

  return <>{renderRoute()}</>;
};

export default ProtectedRoute;
