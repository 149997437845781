import { FC } from 'react';
import useSWR from 'swr';

import { Text } from '~components';
import { useAccount, useCopyToClipboard } from '~hooks';
import { System } from '~lib';
import { Box } from '~components/common/Box';

const OrganisationIdentifier: FC<System> = (props) => {
  const { user } = useAccount();
  const { data } = useSWR(
    user?.organisation ? `/organisations/${user?.organisation}` : null,
  );

  const organisationName = data?.organisation?.name;
  const organisationId = user?.organisation;

  const [, handleCopy] = useCopyToClipboard();

  return (
    <Box
      onClick={() => handleCopy(organisationId)}
      pt={2}
      cursor="pointer"
      fontSize={3}
      {...props}
    >
      {organisationName && (
        <Text fontWeight="regular" color="professional.N900">
          {organisationName} - {organisationId}
        </Text>
      )}
    </Box>
  );
};

export default OrganisationIdentifier;
