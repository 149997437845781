export enum Role {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Patient = 'patient',
  GP = 'gp',
  Ophthalmologist = 'ophthal',
  Optometrist = 'opto',
}

/** @note this function resolves roles coming from API responses **/
export const getRoleName = (role) =>
  Object.keys(Role)[Object.values(Role).indexOf(role)];
