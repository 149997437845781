import { DefaultTheme } from 'styled-components';

export default (
  colors: DefaultTheme['colors'],
  role: string,
  professionalColor: string,
  patientColor?: string,
) => {
  let color = colors.professional[`${professionalColor}`];

  if (role !== 'professional') {
    color = colors.patient[`${patientColor || professionalColor}`];
  }

  return color;
};
