import styled from 'styled-components';
import { Box } from '../Box';

interface IAvatar {
  src?: string | undefined;
  initials: string;
}

const Avatar = styled(Box)<IAvatar>`
  width: 48px;
  height: 48px;
  flex-shrink: 0;

  position: relative;

  background-image: url('${(p) => p.src}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(p) => p.theme.colors.success};

  ${(p) =>
    typeof p.src === 'undefined' &&
    `
    &::after {
      content: ${p.initials};

      position: absolute;
      top: calc(50% - 24px);
    }
  `}

  border-radius: 50px;
`;

export default Avatar;
