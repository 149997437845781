import { FC } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

interface Props {
  images: any[];
  isOpen: boolean;
  close: any;
  currentIndex: number;
}

const ImageViewer: FC<Props> = ({ images, isOpen, close, currentIndex }) => {
  const styleFn = (styleObj) => ({ ...styleObj, zIndex: 10000000 });

  return (
    <ModalGateway>
      {isOpen ? (
        <Modal
          styles={{ blanket: styleFn, positioner: styleFn }}
          onClose={close}
        >
          <Carousel currentIndex={currentIndex} views={images} />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};

export default ImageViewer;
