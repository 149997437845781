import { useState } from 'react';

const useImageViewer = (images: any[]) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openImageViewer = (index: any) => {
    if (images[index]) {
      setCurrentIndex(index);
      setIsOpen(true);
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  return { currentIndex, isOpen, close, openImageViewer };
};

export default useImageViewer;
