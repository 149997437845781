import styled from 'styled-components';
import { NavItem } from './NavItem';

export const NavLink = styled(NavItem)`
  position: relative;
  max-width: 130px;

  ${(p) =>
    p.isActive &&
    `
    font-weight: 600;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .25;
      transition: background 1s ease;
      background-color: ${p.theme.getRoleColor('primary', 'primary')};
    }
  `}

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    &:after {
      content: unset;
    }

    ${(p) =>
      p.isActive &&
      `
      box-shadow: 0 0 0 1px ${p.theme.colors.white};
      opacity: 1;
    `}

    &:last-of-type {
      margin-top: auto;
    }
  }
`;
